<div [cxFocus]="focusConfig" (esc)="closeModal()">
  <div class="modal-header" [class.justify-content-start]="isAuthPage">
    <rrs-icon
      tabindex="0"
      (keydown.enter)="setStep(AUTH_STEPS.loginEmailOrPhone)"
      (click)="setStep(AUTH_STEPS.loginEmailOrPhone)"
      *ngIf="
        [AUTH_STEPS.passwordReset, AUTH_STEPS.loginPassword].includes(authStep)
      "
      [icon]="ICON_TYPE_LIST.CHEVRON_LEFT"
      [size]="16"
      class="forgot-password-back-icon"></rrs-icon>

    <div class="text-uppercase fs-5 fw-semibold">
      {{ authStepTitles[authStep] | cxTranslate }}
    </div>

    <button
      (click)="closeModal()"
      *ngIf="!isAuthPage"
      aria-label="Close"
      class="btn-close"
      type="button"></button>
  </div>

  <div [class.modal-body]="!isAuthPage">
    <rrs-global-message
      aria-atomic="true"
      aria-live="assertive"></rrs-global-message>
    <ng-container [ngSwitch]="authStep">
      <ng-container *ngSwitchCase="AUTH_STEPS.loginEmailOrPhone">
        <ng-container *ngTemplateOutlet="loginEmailOrPhone"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="AUTH_STEPS.loginPassword">
        <ng-container *ngTemplateOutlet="loginPassword"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="AUTH_STEPS.passwordReset">
        <ng-container *ngTemplateOutlet="forgotPassword"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="AUTH_STEPS.register">
        <ng-container *ngTemplateOutlet="register"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="AUTH_STEPS.registerOptional">
        <ng-container *ngTemplateOutlet="registerOptional"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="AUTH_STEPS.registerCompleted">
        <ng-container *ngTemplateOutlet="registerCompleted"></ng-container>
      </ng-container>
    </ng-container>

    <!--  @todo: add links when pages will be available-->
    <p class="mt-4 fs-7">
      By signing in you are agreeing to our website
      <a href="/terms" target="_blank">Terms of Use</a>,
      <a href="/rewards-terms" target="_blank">Rewards Terms and Conditions</a>
      and <a href="/privacy" target="_blank">Privacy Policy</a>.
    </p>
  </div>

  <ng-template #loginEmailOrPhone>
    <p class="mb-3">{{ 'rrs.loginForm.signInSignUpText' | cxTranslate }}</p>
    <rrs-global-message [scopeName]="'login'"></rrs-global-message>

    <label class="form-label mt-2" for="userId">
      {{ 'rrs.loginForm.emailOrPhone' | cxTranslate }}
    </label>

    <form
      #ngForm="ngForm"
      (submit)="validateUser(this.emailPhoneForm.get('emailOrPhone').value)"
      [formGroup]="emailPhoneForm">
      <input
        [ngClass]="
          ngForm.submitted
            ? (emailPhoneForm.get('emailOrPhone') | rrsIsControlValid)
            : ''
        "
        class="form-control"
        formControlName="emailOrPhone"
        id="userId"
        placeholder="email / phone"
        type="text" />

      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="emailPhoneForm.get('emailOrPhone')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>

      <button
        [disabled]="isLoading$ | async"
        class="text-uppercase btn btn-primary mt-4 with-loader"
        type="submit">
        {{
          (data?.showGuestLogin
            ? 'rrs.loginForm.continueToCheckout'
            : 'rrs.loginForm.signInSignUp'
          ) | cxTranslate
        }}
        <rrs-loader *ngIf="isLoading$ | async"></rrs-loader>
      </button>

      <div *ngIf="data?.showGuestLogin" class="guest-login-container">
        <div class="text-center">or</div>
        <button
          class="btn btn-secondary with-loader"
          [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
          (click)="closeModal()"
          [disabled]="isLoading$ | async">
          {{ 'rrs.loginForm.guestLogin' | cxTranslate }}
          <rrs-loader *ngIf="isLoading$ | async"></rrs-loader>
        </button>
      </div>
    </form>

    <ng-container *ngTemplateOutlet="forgotPasswordLink"></ng-container>
  </ng-template>

  <ng-template #loginPassword>
    <p class="mb-3">{{ 'rrs.loginForm.signInSignUpText' | cxTranslate }}</p>

    <rrs-global-message [scopeName]="'login'"></rrs-global-message>

    <label class="form-label mt-2" for="userId">
      {{ 'rrs.loginForm.password' | cxTranslate }}
    </label>

    <form #ngForm="ngForm" (submit)="doLogin()" [formGroup]="passwordForm">
      <input
        [ngClass]="
          ngForm.submitted
            ? (passwordForm.get('password') | rrsIsControlValid)
            : ''
        "
        class="form-control"
        formControlName="password"
        id="password"
        placeholder="password"
        type="password" />
      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="passwordForm.get('password')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>

      <div class="d-flex gap-3 mt-4">
        <button
          *ngIf="isAuthPage"
          (click)="goToLoginPage()"
          class="btn btn-secondary">
          {{ 'rrs.common.cancel' | cxTranslate }}
        </button>
        <button class="text-uppercase btn btn-primary" type="submit">
          {{ 'rrs.loginForm.signIn' | cxTranslate }}
          <rrs-loader *ngIf="isLoading$ | async"></rrs-loader>
        </button>
      </div>
    </form>

    <ng-container *ngTemplateOutlet="forgotPasswordLink"></ng-container>
  </ng-template>

  <ng-template #forgotPassword>
    <p class="mb-0">Enter your email to reset your password</p>
    <rrs-global-message [scopeName]="'login'"></rrs-global-message>

    <label class="form-label mt-4" for="resetPasswordInput">
      {{ 'rrs.loginForm.emailOrPhone' | cxTranslate }}
    </label>
    <form
      #ngForm="ngForm"
      (submit)="resetPassword()"
      [formGroup]="emailPhoneForm">
      <input
        [ngClass]="
          ngForm.submitted
            ? (emailPhoneForm.get('emailOrPhone') | rrsIsControlValid)
            : ''
        "
        class="form-control"
        formControlName="emailOrPhone"
        id="resetPasswordInput"
        placeholder="email / phone"
        type="text" />

      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="emailPhoneForm.get('emailOrPhone')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>

      <button
        [disabled]="isLoading$ | async"
        class="text-uppercase btn btn-primary mt-4"
        type="submit">
        {{ 'rrs.loginForm.resetPassword' | cxTranslate }}
        <rrs-loader *ngIf="isLoading$ | async"></rrs-loader>
      </button>
    </form>
  </ng-template>

  <ng-template #forgotPasswordLink>
    <div class="mt-4 d-flex justify-content-center">
      <a
        tabindex="0"
        (click)="setStep(AUTH_STEPS.passwordReset)"
        (keydown.enter)="setStep(AUTH_STEPS.passwordReset)"
        class="link-primary cursor-pointer">
        {{ 'rrs.loginForm.forgotPassword' | cxTranslate }}
      </a>
    </div>
  </ng-template>

  <ng-template #register>
    <p class="mb-0">
      We don't seem to have your account on file. To become a member just fill
      out the fields, create a password and you're in!
    </p>

    <div class="mt-4">
      <rrs-global-message [scopeName]="'signup'"></rrs-global-message>
    </div>

    <div class="signup-sub-text mt-4">Required Information:</div>

    <form #ngForm="ngForm" (submit)="doSignUp()" [formGroup]="signUpForm">
      <div [ngClass]="{ 'row row-cols-1 row-cols-md-2': isAuthPage }">
        <div class="form-group col">
          <label class="form-label mt-2" for="first-name">
            {{ 'rrs.loginForm.firstName' | cxTranslate }}
          </label>

          <input
            [ngClass]="
              ngForm.submitted
                ? (signUpForm.get('firstName') | rrsIsControlValid)
                : ''
            "
            [placeholder]="'rrs.loginForm.firstName' | cxTranslate"
            class="form-control"
            formControlName="firstName"
            id="first-name"
            type="text" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="signUpForm.get('firstName')"
            aria-atomic="true"
            aria-live="assertive">
          </rrs-form-errors>
        </div>

        <div class="form-group col">
          <label class="form-label mt-2" for="last-name">
            {{ 'rrs.loginForm.lastName' | cxTranslate }}
          </label>

          <input
            [ngClass]="
              ngForm.submitted
                ? (signUpForm.get('lastName') | rrsIsControlValid)
                : ''
            "
            [placeholder]="'rrs.loginForm.lastName' | cxTranslate"
            class="form-control"
            formControlName="lastName"
            id="last-name"
            type="text" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="signUpForm.get('lastName')"
            aria-atomic="true"
            aria-live="assertive">
          </rrs-form-errors>
        </div>

        <div class="form-group col">
          <label class="form-label mt-2" for="su-email">
            {{ 'rrs.loginForm.email' | cxTranslate }}
          </label>

          <input
            [ngClass]="
              ngForm.submitted
                ? (signUpForm.get('email') | rrsIsControlValid)
                : ''
            "
            [placeholder]="'rrs.loginForm.email' | cxTranslate"
            class="form-control"
            formControlName="email"
            id="su-email"
            type="text" />

          <rrs-form-errors
            *ngIf="ngForm.submitted"
            [control]="signUpForm.get('email')"
            aria-atomic="true"
            aria-live="assertive">
          </rrs-form-errors>
        </div>

        <div class="form-group col">
          <label class="form-label mt-2" for="su-password">
            {{ 'rrs.loginForm.password' | cxTranslate }}
          </label>

          <input
            [ngClass]="
              ngForm.submitted
                ? (signUpForm.get('password') | rrsIsControlValid)
                : ''
            "
            [placeholder]="'rrs.loginForm.password' | cxTranslate"
            class="form-control"
            formControlName="password"
            id="su-password"
            type="password" />
        </div>
      </div>
      <rrs-password-errors
        [displayMode]="isAuthPage ? 'grid' : 'list'"
        [errors]="signUpForm.get('password').errors"
        [showErrors]="
          signUpForm.get('password')?.touched ||
          signUpForm.get('password')?.dirty
        ">
      </rrs-password-errors>
      <div class="d-flex gap-2">
        <button
          type="button"
          (click)="isAuthPage ? goToLoginPage() : closeModal()"
          class="text-uppercase btn btn-secondary with-loader">
          {{ 'rrs.common.cancel' | cxTranslate }}
        </button>

        <button
          type="submit"
          [disabled]="isLoading$ | async"
          class="text-uppercase btn btn-primary with-loader">
          {{ 'rrs.loginForm.signUp' | cxTranslate }}

          <rrs-loader *ngIf="isLoading$ | async"></rrs-loader>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #registerOptional>
    <div>
      <rrs-global-message [scopeName]="'signup'"></rrs-global-message>
    </div>

    <div class="signup-sub-text">Optional Information:</div>

    <form
      #ngForm="ngForm"
      (submit)="saveOptional()"
      [formGroup]="signUpOptionalForm">
      <label class="form-label mt-3" for="su-phone">
        {{ 'signup.phone' | cxTranslate }}
      </label>

      <input
        [ngClass]="
          ngForm.submitted
            ? (signUpOptionalForm.get('phone') | rrsIsControlValid)
            : ''
        "
        class="form-control"
        formControlName="phone"
        id="su-phone"
        placeholder="(555) 555-5555"
        rrsPhoneNumberInput
        type="tel" />

      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="signUpOptionalForm.get('phone')"
        aria-atomic="true"
        aria-live="assertive">
      </rrs-form-errors>

      <label class="form-label mt-3" for="monthOfBirth">
        {{ 'rrs.account.form.monthOfBirth' | cxTranslate }}
      </label>

      <ng-select
        (close)="monthSelectOpened = false"
        (open)="monthSelectOpened = true"
        [class.month-of-birth-label]="monthSelectOpened"
        [clearable]="false"
        [items]="monthOfBirthOptions"
        bindValue="value"
        formControlName="monthOfBirth"
        id="monthOfBirth"
        labelForId="monthOfBirth"
        placeholder="Select Month">
      </ng-select>

      <rrs-form-errors
        *ngIf="ngForm.submitted"
        [control]="signUpOptionalForm.get('monthOfBirth')"></rrs-form-errors>

      <div class="d-flex gap-2 mt-3">
        <button
          (click)="skipOptional()"
          type="button"
          class="btn btn-secondary with-loader">
          {{ 'rrs.common.skip' | cxTranslate }}
        </button>

        <button
          type="submit"
          [disabled]="isLoading$ | async"
          class="btn btn-primary with-loader">
          {{ 'rrs.common.continue' | cxTranslate }}

          <rrs-loader *ngIf="isLoading$ | async"></rrs-loader>
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #registerCompleted>
    <div class="d-flex w-100 justify-content-center">
      <rrs-icon [icon]="icon.ALERT_SUCCESS" [size]="160"></rrs-icon>
    </div>

    <div class="mt-3">
      <p class="mt-3 text-center fw-bold">
        {{ 'rrs.loginForm.allSet' | cxTranslate }}
      </p>

      <button
        (click)="isAuthPage ? goToHomePage() : closeModal()"
        class="btn btn-block btn-primary mt-2">
        {{ 'rrs.loginForm.keepShopping' | cxTranslate }}
      </button>

      <button
        (click)="closeModal(); goToAccountPage()"
        class="btn btn-block btn-secondary mt-3">
        {{ 'rrs.loginForm.viewAccount' | cxTranslate }}
      </button>
    </div>
  </ng-template>
</div>
